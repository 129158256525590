
import { Delete, Edit, View, Search } from "@element-plus/icons-vue";
import { defineComponent } from "vue";
import { client } from '@/client';
import { ElMessage } from "element-plus";
import { addLabelValue, tree } from "@/utils/tools";
export default defineComponent({
  name: "tag-index",
  setup() {
    return {
      Edit,
      Delete,
      View,
      Search,
    };
  },
  data: () => {
    return {
      tableData: [] as any,
      multipleSelection: [],
      count: 0,
      cur: 1,
      keywords: "",
    };
  },
  computed: {},
  created() {
    let { cur = 1, keywords = "" } = this.$route.query;

    this.cur = + (cur as number|1);
    this.keywords = keywords as string | "";

    this.search();
  },
  watch: {
    $route(to, from) {
      if (to.name == "tag-index") {
        let { cur, keywords } = to.query;
        this.cur = +cur;
        this.keywords = keywords;
        this.search();
      }
    },
  },
  methods: {
    //清空搜索
    clearSearch() {
      this.$router.replace({
        name: "tag-index",
        query: { cur: 1, cid: 0, keywords: "" },
      });
    },

    doSearch() {
      this.$router.replace({
        name: "tag-index",
        query: { cur: this.cur, keywords: this.keywords },
      });

      this.search();
    },
    //查询
    async search() {
      try {
        let res = await client.callApi("tag/List",{cur:this.cur,key: this.keywords});
        if (res.isSucc) {
          this.tableData = [...res.res.list];
          this.count = res.res.count;
        }
      } catch (error) {
        console.log(error);
      }
    },

    //翻页
    handleCurrentChange(e: string|number) {
      this.cur = +e;
      this.doSearch();
    },

    toggleSelection(rows: any[]) {
      let refs:any = this.$refs.multipleTable;
      if (rows) {
        rows.forEach((row) => {
          refs.toggleRowSelection(row);
        });
      } else {
        refs.clearSelection();
      }
    },

    handleSelectionChange(val: never[]) {
      this.multipleSelection = val;
    },

    toEdit(e: { id: any; }) {
      let id = e.id;
      this.$router.push({ name: "tag-edit", params: { id: id } });
    },

    //删除
    async handleDel(e: { id: any; }) {
      let id = e.id;
      try {
        let res = await client.callApi("tag/Del",{id:id});
        if (res.isSucc) {
          ElMessage({
            message: "删除成功 ^_^",
            type: "success",
          });
          this.search();
        } else {
          ElMessage({
            message: res.err.message,
            type: "error",
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
});
